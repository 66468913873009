import { useApp } from "@realm/react";
import { useContext, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import {ObjectId} from "bson";

const useCreateDiscount = () => {
    const app = useApp();
    const { user } = useContext(UserContext);
    let mongo: { db: (arg0: string) => { (): any; new(): any; collection: { (arg0: string): any; new(): any; }; }; };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const updateUser = () => {
        if (user) {
            mongo = user.mongoClient("mongodb-atlas");
        }
    };

    updateUser();

    const addDiscount = async (type: string, value: number, name: string) => {
        setLoading(true);

        const discountsCollection = mongo.db("pos").collection("Discount");

        const newDiscount = {
            _id:{ '$oid' : new ObjectId().toString()},
            type,
            value: type === "percentage" ? value * 10 : value * 100,
            name,
            owner_id: app.currentUser.id,
            siteId: app.currentUser.customData.currentSite.toString()
        }

        try {
            const result = await discountsCollection.insertOne(newDiscount);
            console.log(`Successfully inserted item with _id: ${result.insertedId}`)
        } catch (error) {
            console.error(`Failed to insert item: ${error}`);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return {
        addDiscount,
        loading,
        error
    };
};

export default useCreateDiscount;
